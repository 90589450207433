<template>
    <div class="appointment">
        <div class="display-section">
            <div class="display-desc" v-if="currentTeacher.id&&currentTeacher.id!==0">
                <span class="desc-key">确认预约信息：</span>
                <span class="desc-value">
                    {{currentTeacher.name}}老师
                    <span v-if="choseDate!==''">{{formatMoment(choseDate,'MM月DD日',true)}}</span>
                    {{chooseTimeItem.range}}

                </span>
            </div>
            <div class="display-desc" v-else>
                <span class="desc-key">确认预约信息：</span>
                <span class="desc-value-gray">
                    请选择上课老师及上课时间
                </span>
            </div>
            <div class="display-handler">

                <el-button v-if="choseDate&&currentTeacher.id&&currentTeacher.id!==0" class="button-next" @click="handleSubmit">下一步</el-button>
                <el-button v-else class="button-next button-next-gray">下一步</el-button>
            </div>
        </div>
        <div class="filter-section">
            <div class="filter-teacher">
                <div class="teacher-list">
                    <div class="teacher-item"
                         v-for="teacher in teachers"
                         :class="[currentTeacher.id === teacher.id?'teacher-item-active':'',teacher.is_free?'':'teacher-item-gray']"
                         :key="teacher.id"
                         @click="handleChooseTeacher(teacher)"
                    >
                        <div class="item-avatar">
                            <span  v-if="teacher.avatar==='All'">ALL</span>
                            <img  v-else :src="teacher.avatar" alt="" class="userImg">
                            <img v-if="teacher.id === lastTeacherId && !teacher.is_showTag " class="avatar-tag" :src="`${$domain}image/ow/mini/tip2.png`" mode="aspectFit">
                            <div v-else-if="teacher.is_showTag" class="tag-canappointment">可约</div>
                        </div>
                        <div class="item-name">{{teacher.name}}</div>
                    </div>
                </div>
            </div>
        </div>
      <div class="tips">时间已自动转化为当地时区~</div>
        <div class="appointments-section" v-if="scheduleList.length>0" :style="{height: appointmentHight ? `${appointmentHight + 'px'}` : '77%'}">
            <div class="schedule">
                <div class="dates">
                    <div class="date_time" :style="{height: tableHeight}" >
                        <div class="status">
                            <div class="line">
                                <span class="line_top_right">日期</span>
                                <span class="line_bottom_left">时间</span>
                            </div>
                        </div>
                        <div v-for="(time,index) in timeRangeList" :key="index" class="status">
                            {{ time.range }}
                        </div>
                    </div>
                    <div class="date_con" :style="{height: tableHeight}" v-for="moment in scheduleList" :key="moment.date">
                        <div class="status" v-html="moment.dateShow"></div>
                        <div class="status"
                             v-for="(item,index) in moment.data"
                             :key="index + moment.date"
                             :style="{background: item.bg}"
                        >
                            <template v-if="item.is_open">
                               <div class="appointment-status-full" v-if="item.is_appointment">已约满</div>
                                <div class="appointment-status-can" :class="choseDate===moment.date&&chooseTimeItem.index===item.index?'appointment-status-can-active':''" @click="handleChooseTime(moment.date,item)" v-else>可预约</div>
                            </template>
                           <div v-else class="appointment-status-disabled">不可预约</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <message
          v-if="messageIsShow"
          :messageIsShow="messageIsShow"
          :currentTeacher="currentTeacher"
          :choseDate="choseDate"
          :chooseTimeItem="chooseTimeItem"
          @offPop="offPop"
          @nextOffPop="nextOffPop"
        ></message>
    </div>
</template>

<script>
import appointmentJs from './appointment'

export default appointmentJs
</script>

<style lang="less" scoped>
.appointment {
    box-sizing: border-box;
    width: 940px;
    height: 100%;
    padding: 0 30px 30px;

    .display-section {
        width: 100%;
        height: 68px;
        border-bottom: 1px solid #e6e6e6;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .display-desc {
            font-size: 14px;

            .desc-key {
                color: #666;
            }

            .desc-value {
                color: #333;
            }
            .desc-value-gray{
                color: #999
            }
        }

        .button-next {
            width: 72px;
            height: 28px;
            padding: 0;
            border-radius: 4px;
            background: #29d087;
            color: #fff;
            text-align: center;
            align-items: center;
        }
      .button-next-gray{
        background: #ccc;

      }
    }

    .filter-section {
        width: 100%;
        height: 98px;
        display: flex;
        align-items: center;
        overflow: hidden;
        .filter-plan {
            width: 92px;
            //position: relative;
            cursor: pointer;
            flex-shrink: 0;

            .plan {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .plan-show {
                    display: flex;
                    align-items: center;
                    color: #333;
                    font-weight: bold;
                    font-size: 16px;
                }

                .filter-line {
                    width: 1px;
                    height: 30px;
                    background: #e6e6e6;
                    height: 30px;
                }
            }

            .filter-box {
                position: absolute;
                border: 1px solid #E6E6E6;
                border-radius: 3px;
                padding: 10px;
                z-index: 999;
                background: #fff;

                .filter-plan-item {
                    width: 72px;
                    height: 36px;
                    line-height: 36px;
                    border-bottom: 1px solid #E6E6E6;
                    cursor: pointer;
                }

                .filter-plan-item:last-child {
                    border-bottom: none;
                }

                .filter-plan-item-active {
                    font-weight: bold;
                    color: #333;
                }
            }
        }
        .triangle {
            width: 0;
            height: 0;
            border-right: 9px solid transparent;
            border-left: 9px solid transparent;
            border-top: 9px solid #29d087;
            margin-left: 9px;
        }
        .unTriangle {
            width: 0;
            height: 0;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-bottom: 9px solid #29d087;
            margin-left: 9px;
        }
        .filter-teacher{
            overflow: auto;
            .teacher-list{
                display: flex;
                .teacher-item{
                    position: relative;
                    margin-left: 30px;
                    flex-shrink: 0;
                    cursor: pointer;
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    align-items: center;
                    .item-avatar{
                        position: relative;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        border:1px solid #e6e6e6 ;
                        font-size: 0;
                        .userImg{
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                        }

                        span{
                            text-align: center;
                            line-height: 40px;
                            font-size: 16px;
                            font-weight: bold;
                        }
                        .avatar-tag{
                            position: absolute;
                            right: -15px;
                            width: 28px;
                            top: 0;
                            z-index: 999;
                        }
                        .tag-canappointment{
                            position: absolute;
                            top: 0;
                            right: -15px;
                            width: 30px;
                            background: #FFE6E6;
                            border-radius: 3px;
                            font-size: 12px;
                            font-weight: 500;
                            color: #FF4747;
                            z-index: 999;
                        }
                    }


                    .item-name{
                        font-size: 12px;
                        color:#666 ;
                    }



                }
                .teacher-item-active{
                    .item-avatar{
                        border: 1px solid #29D087;
                    }
                    .item-name{
                        color: #29d087;
                    }
                }
                .teacher-item-gray{
                    opacity: 0.5;
                }
            }
        }
    }
  .tips{
    font-size: 12px;
    color: #999;
    text-align: left;
    padding: 5px 0;
  }
    .appointments-section{
        height: 77%;
        .schedule {
            display: flex;
            border-top: 1px solid #ebeef5;
            border-right: 1px solid #ebeef5;
            // height: calc(100vh - 160px);
            height: 100%;
            overflow: hidden;
            font-size: 12px;
            border-radius: 4px;
            .line {
                width: 100%;
                height: 100%;
                background: #e5eded;
                background: linear-gradient(to top right,
                #e5eded 0%,
                rgba(229, 237, 237, 1) calc(50% - 1px),
                rgba(243, 245, 249, 1) 50%,
                rgba(229, 237, 237, 1) calc(50% + 1px),
                rgba(229, 237, 237, 1) 100%);
                position: relative;
                font-size: 12px;

                .line_bottom_left {
                    position: absolute;
                    left: 21px;
                    bottom: 6px;
                }

                .line_top_right {
                    position: absolute;
                    right: 21px;
                    top: 6px;
                }
            }

            .dates {
                width: 100%;
                display: flex;
                flex-direction: row;
                overflow-x: scroll;
                border-bottom: 1px solid #ebeef5;

                .date_time {
                    width: 112px;
                    flex-shrink: 0;
                    position: sticky;
                    left: 0px;
                    z-index: 3;
                    border-right: 1px solid #EBEEF5;

                    .status {
                        flex-shrink: 0;
                        width: 100%;
                        height: 48px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-left: 1px solid #ebeef5;
                        border-right: 1px solid #ebeef5;
                        background: #F5F7FA;

                    }

                    .status:first-child {
                        position: sticky;
                        top: 0px;
                        z-index: 2;
                        background: #fff;
                    }
                }

                .date_con {
                    width: 128px;
                    flex-shrink: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .status {
                        flex-shrink: 0;
                        width: 100%;
                        height: 48px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        //border-bottom: 1px solid #ebeef5;
                        //border-left: 1px solid #ebeef5;
                        font-size: 14px;

                        .appointment-status-disabled{

                        }
                        .appointment-status-full{
                            color: #FF6C6C
                        }
                        .appointment-status-can{
                            width: 58px;
                            height: 28px;
                            border-radius: 3px;
                            background: #29d087;
                            color: #fff;
                            line-height: 28px;
                            cursor: pointer;
                            user-select: none;

                        }
                        .appointment-status-can-active{
                            background: orange;
                        }
                    }


                    .status:first-child {
                        position: sticky;
                        top: 0px;
                        z-index: 1;
                        background: #F5F7FA;
                        font-weight: bold;
                        color: #333;
                    }
                }
            }
            .dates::-webkit-scrollbar {
                display: none;
            }
        }
    }


}
</style>
